import { twMerge } from 'tailwind-merge';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';

type Props = {
  className?: string;
};

export function CircularProgress({ className }: Props) {
  return (
    <FontAwesomeIcon
      icon={faSpinner}
      className={twMerge('h-4 w-4 animate-spin bg-transparent text-primary', className)}
    />
  );
}
